/* Core */
.rwt-border-blue {
    border-color: #00A1C9 !important;
}

.rwt-border-dark-blue {
    border-color: #32325c !important;
}

.rwt-bg-blue {
    background-color: #00A1C9;
}

.rwt-txt-blue {
     color: #00A1C9;
}

.rwt-txt-dark-blue {
    color: #32325c;
}

.rwt-secondary-btn {
    background-color: #EC7211; 
    transition: .5s;
    font-weight: bold;
} 
 
.rwt-secondary-btn:hover {
    text-decoration: none;
    background-color: #ec7311b2; 
    color: white; 
} 
 
.rwt-primary-btn {
    background-color: #00A1C9; 
    color: white; 
    transition: .5s;
    font-weight: bold;
} 
 
.rwt-primary-btn:hover {
    text-decoration: none;
    background-color: #00a1c9d5; 
    color: white; 
}

.rwt-txt-light-blue {
    color: #00A1C9;
}

.rwt-txt-orange {
    color: #EC7211;
}

.cursor-pointer {
    cursor: pointer;
}

/* default fonts */
.fs-8rem {
    font-size: 0.5rem;
}

.fs-9rem {
    font-size: 0.5625rem;
}

.fs-10rem {
    font-size: 0.625rem;
}

.fs-11rem {
    font-size: 0.688rem;
}

.fs-12rem {
    font-size: 0.75rem;
}

.fs-13rem {
    font-size: 0.8125rem;
}

.fs-14rem {
    font-size: 0.875rem;
}

.fs-16rem {
    font-size: 1rem;
}

.fs-18rem {
    font-size: 1.125rem;
}

.fs-20rem {
    font-size: 1.25rem;
}

.fs-22rem {
    font-size: 1.375rem;
}

.fs-24rem {
    font-size: 1.5rem;
}
